import React from 'react';

const HowItWorks = () => {
    return (
        <section id="about-me" className="block spacer p-top-xl p-bottom-lg">
            <div className="wrapper">

                <div className="row gutter-width-md with-pb-lg justify-content-center">
                    <div className="col-xl-7 col-lg-7 col-md-5 col-sm-12">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <div className="text-center" data-aos="fade-up">
                                <h2>Skúste prvý tréning zdarma a zistite či je to to pravé pre vás.</h2>
                            </div>

                            <div className="sub-title spacer p-top-xs text-center p-bottom-lg" data-aos="fade-up">
                                <p><small>Žiadne podmienky, tlak ani záväzky.
                                </small></p>
                            </div>

                            <div className="spacer p-top-lg d-flex flex-row justify-content-center p-top-xl" data-aos="fade-up">
                               <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                                   <div className="text-center" data-aos="fade-up">
                                       <h2>1.</h2>
                                   </div>

                                   <div className="description spacer p-top-md text-center" data-aos="fade-up">
                                       <p>Zavolajme si a prediskutujme vaše ciele a predstavy.</p>
                                   </div>
                               </div>
                               <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                                   <div className="text-center" data-aos="fade-up">
                                       <h2>2.</h2>
                                   </div>

                                   <div className="description spacer p-top-md text-center" data-aos="fade-up">
                                       <p>Na základe vašich prianí vám navrhnem plán na mieru.</p>
                                   </div>
                               </div>
                               <div className="card col-xl-4 col-lg-4 col-md-5 col-sm-12">
                                   <div className="text-center" data-aos="fade-up">
                                       <h2>3.</h2>
                                   </div>

                                   <div className="description spacer p-top-md text-center" data-aos="fade-up">
                                       <p>Po vyskúšaní prvého tréningu zdarma si dohodneme pravidelný harmonogram spolupráce.</p>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
