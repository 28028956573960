import React from 'react';

const AboutMe = () => {
    return (
        <section id="about-me" className="block spacer p-top-xl p-bottom-lg">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-lg justify-content-center">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <div className="about-me-img" data-aos="zoom-in-right">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/pages/aboutme/aboutme.jpg" alt="About" />
                                </div>
                            </div>

                            <div className="img-2" data-aos="fade-up">
                                <img src="assets/img/demo/03_img.png" alt="signed" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <div className="title" data-aos="fade-up">
                                <h2 className="pb-0">O mne</h2>
                            </div>

                            <div className="sub-title spacer p-top-lg" data-aos="fade-up">
                                <h3>Som v pohybe, najčastejšie ma zastihnete na kopcoch a v horách. Harmónia tela, duše a mysle je to, čím sa neustále zaoberám.</h3>
                            </div>

                            <div className="description spacer p-top-lg" data-aos="fade-up">
                                <p>Kondičná trénerka, inštruktorka outdoorových aktivít. Narodená 19. 7. 1970 v Liptovskom Mikuláši, tu aj dnes žijem a pracujem. Každý človek je veľká individualita, to rešpektujem a tak pristupujem k vytvoreniu programu každému presne na mieru. Teším sa pokrokom a úspechom klienta, hľadáme spolu motiváciu, optimalizáciu. Vždy sa snažím nájsť najvhodnejšie prírodné prostredie, ktoré je nielen príjemným benefitom, no čistota a pokoj sú zdrojom energie a harmónie. Ja sama najviac svojho voľného času strávim na horách, v každom ročnom období a takmer v každom počasí.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;
