import React from 'react';

const HeaderLogo = ( { image } ) => {
    return (
        <div className="header-logo">
            <a className="logo transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                <h3 className="large">Ivana Mudroňová</h3>
            </a>
        </div>
    );
};

export default HeaderLogo;
