import React from 'react';

const Review = () => {
    return (
        <section id="review" className="block spacer p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-lg justify-content-center">

                    <div className="col-xl-7 col-lg-7 col-md-5 col-sm-12">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <div className="title" data-aos="fade-up">
                                <h3 className="pb-0 text-center">"Som veľmi vďačná za túto trénerôčku. Konečne mám pocit, že sa zo mňa stáva bežec. Dávam to aj do kopcov, čo som tvrdila, že mňa behať kopce nebaví a že ich behávať nebudem. Spoznala som veľa nových trás. Celkovo cítim zlepšenie vo všetkom. Ešte ma čaká kopec makačky a driny na ceste za mojím cieľom odbehnúť polmaratón. Ale dôležité je NEVZDÁVAŤ SA. Takže ďakujem a ide sa ďalej.“</h3>
                            </div>

                            <div className="description spacer p-top-lg text-center" data-aos="fade-up">
                                <p> - Marcela Š.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Review;
