import React from 'react';
import ReactDOM from 'react-dom';
import './assets/sass/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-Y2T0R1X3CT";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render( <App />, document.getElementById( 'root' ) );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
