import React, { useState, useRef } from 'react';
import Fade from 'react-bootstrap/Fade';
import { Link } from 'react-scroll';

const ResumeMenu = ( props ) => {
    const [show, setShow] = useState(false);
    const resume_menu = useRef();

    const openDropDown = () => {
        resume_menu.current.style.display = "block";
        setTimeout(setShow(true), 300);
    };

    const closeDropDown = () => {
        setShow(false);
        setTimeout(() => { resume_menu.current.style.display = "" }, 300);
    };

    return (
        <li onMouseEnter={() => openDropDown()} onMouseLeave={() => closeDropDown()} className={ ( show ? "show" : "" ) + " nav-item nav-item-has-children dropdown-hover" }>
            <a title="Resume" href={ process.env.PUBLIC_URL + "/" }>Menu</a>

            <span className="dropdown-toggle dropdown-custom-icon">
                <span className="dropdown-icon">
                    <i className="fas fa-chevron-down"></i>
                </span>
            </span>

            <Fade in={show}>
                { props.homePage ? 
                    (<ul className="dropdown-menu" ref={resume_menu}>
                        <li>
                            <Link title="About me" className="dropdown-item"  smooth={ true } duration={ 0 } href="#about-me" to="about-me">O mne</Link>
                        </li>
            
                        <li>
                            <Link title="What I do" className="dropdown-item" smooth={ true } duration={ 0 } href="#what-i-do" to="what-i-do">Služby na mieru pre vás</Link>
                        </li>

                        <li>
                            <Link title="services" className="dropdown-item" smooth={ true } duration={ 0 } href="#services" to="services">Čo pre vás môžem spraviť?</Link>
                        </li>

                        <li>
                            <Link title="Experience" className="dropdown-item" smooth={ true } duration={ 0 } href="#experience" to="experience">Skúsenosti</Link>
                        </li>

                        <li>
                            <Link title="Portfolio" className="dropdown-item" smooth={ true } duration={ 0 } href="#portfolio" to="portfolio">Galéria</Link>
                        </li>
            
                        <li>
                            <Link title="Testimonials" className="dropdown-item" smooth={ true } duration={ 0 } href="#testimonials" to="testimonials">Moji klienti</Link>
                        </li>
            
                        {/*<li>*/}
                        {/*    <Link title="Clients" className="dropdown-item" smooth={ true } duration={ 0 } href="#clients" to="clients">Klienti</Link>*/}
                        {/*</li>*/}
            
                        <li>
                            <Link title="Contact me" className="dropdown-item" smooth={ true } duration={ 0 } href="#contact-me" to="contact-me">Kontaktujte ma</Link>
                        </li>
                    </ul>)
                    :
                    (<ul className="dropdown-menu" ref={resume_menu}>
                        <li>
                            <a title="About me" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#about-me" }>O mne</a>
                        </li>
            
                        <li>
                            <a title="What I do" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#what-i-do" }>Služby na mieru pre vás</a>
                        </li>

                        <li>
                            <a title="services" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#services" }>Čo pre vás môžem spraviť?</a>
                        </li>

                        <li>
                            <a title="Experience" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#experience" }>Skúsenosti</a>
                        </li>
            
                        <li>
                            <a title="Portfolio" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#portfolio" }>Galéria</a>
                        </li>
            
                        <li>
                            <a title="Testimonials" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#testimonials" }>Moji klienti</a>
                        </li>
            
                        {/*<li>*/}
                        {/*    <a title="Clients" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#clients" }>Klienti</a>*/}
                        {/*</li>*/}
            
                        <li>
                            <a title="Contact me" className="dropdown-item" href={ process.env.PUBLIC_URL + "/#contact-me" }>Kontaktujte ma</a>
                        </li>
                    </ul>)
                }
            </Fade>
        </li>
    );
};

export default ResumeMenu;
